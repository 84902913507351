<template>
  <div id="outer_box">
    <div class="outer_list">
      <div class="outer_item" v-for="(item, index) in items" :key="index" @click="onClick(item)" :class="{'wf': item.name == 'wf'}">
        <img :src="item.logo">
      </div>
    </div>
  </div>
</template>

<script>
import { kMobileMenusConfig } from "@/config/Mobile/MenusConfig";
export default {
  name: "Outerbar",
  props: {
    list: Array,
  },
  data() {
    return {
      items: kMobileMenusConfig.outer,
    };
  },
  methods: {
    onClick(item) {
      location.href = item.path
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#outer_box{
  position: fixed;
  right: 0;
  width: calc(100/1080 * 100vw);
  bottom: calc(240/1080 * 100vw);

  .outer_list{
    display: flex;
    // height: calc(120/1080 * 100vw);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .outer_item{
      // background-color: #fff;
      height: calc(100/1080 * 100vw);
      width: calc(100/1080 * 100vw);
      // flex: 1;
      // margin: 1px 0px;
      overflow: hidden;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      // border-radius: 1vw;
      // box-shadow: 0 1vw 2vw #afafaf;
      img{
        height: 100%;
        // border-radius: 1vw;
      }
      &.wf{
        background-color:#fff;

        img{
          height: 80%;
        }
      }
    }
  }
}
</style>