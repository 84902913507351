export const kMobileMenusConfig = {
  jj: [
    {
      name: "集团矩阵",
      icon: require("@/assets/mobile/menus/page_01.png"),
    },
    {
      name: "归农村",
      icon: require("@/assets/mobile/menus/page_02.png"),
    },
    {
      name: "世外梨园",
      icon: require("@/assets/mobile/menus/page_03.png"),
    },
    {
      name: "万亩芦荟",
      icon: require("@/assets/mobile/menus/page_04.png"),
    },
    {
      name: "仓储配送",
      icon: require("@/assets/mobile/menus/page_05.png"),
    },
    {
      name: "媒体报告",
      icon: require("@/assets/mobile/menus/page_13.png"),
    },
  ],
  zz: [
    {
      name: "品牌文化",
      icon: require("@/assets/mobile/menus/page_11.png"),
    },
    {
      name: "自然农法",
      icon: require("@/assets/mobile/menus/page_12.png"),
    },
    {
      name: "公益贡献",
      icon: require("@/assets/mobile/menus/page_14.png"),
    },
    {
      name: "影视文艺",
      icon: require("@/assets/mobile/menus/page_15.png"),
    },
  ],
  sq: [
    {
      name: "蕙芷香遇俱乐部",
      icon: require("@/assets/mobile/menus/page_31.png"),
    },
    {
      name: "自然农法宣讲团",
      icon: require("@/assets/mobile/menus/page_32.png"),
    },
    // {
    //   name: "天下商邦私享会",
    //   icon: require("@/assets/mobile/menus/page_33.png"),
    // },
  ],
  sc: [
    {
      name: "App",
      icon: require("@/assets/mobile/menus/page_41.png"),
    },
    {
      name: "App",
      icon: require("@/assets/mobile/menus/page_42.png"),
    },
  ],
  outer: [
    {
      name: "wf",
      path: "https://wf.hzno.cn/",
      title: "万非",
      logo: require("@/assets/logos/wf.png"),
      show: true,
    },
    // {
    //   name: "txsb",
    //   path: "https://txsb.hzno.cn/",
    //   title: "天下商邦",
    //   logo: require("@/assets/mobile/menus/page_33.png"),
    //   show: true,
    // },
    {
      name: "hz",
      path: "",
      title: " 蕙芷",
      logo: require("@/assets/mobile/menus/page_34.png"),
      show: true,
    },
  ],
}
